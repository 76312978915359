const MobileNavData = [
  {
    title: "About",
    link: "/about",
    subNav: [
      {
        title: "연혁",
        link: "/about/history",
      },
      {
        title: "사업소개",
        link: "/about/business",
      },
    ],
  },
  {
    title: "Brand",
    link: "/brand",
    subNav: [
      {
        title: "사보텐",
        link: "/brand/saboten/story",
        alternativeLink: "/brand/saboten",
      },
      {
        title: "히바린",
        link: "/brand/hibarin/story",
        alternativeLink: "/brand/hibarin",
      },
      {
        title: "타코벨",
        link: "http://koreatacobell.com/",
      },
      {
        title: "센트럴키친",
        link: "/brand?modal=centralkitchen",
      },
      {
        title: "리퍼크",
        link: "/brand?modal=reperk",
      },
    ],
  },
  {
    title: "Product",
    link: "/products",
    subNav: [
      {
        title: "돈카츠",
        link: "/products/homemade/katsu",
      },
      {
        title: "소스",
        link: "/products/homemade/sauce",
      },
    ],
  },
  {
    title: "Support",
    link: "/supports",
    subNav: [
      {
        title: "멤버쉽",
        link: "/supports/membership",
      },
      {
        title: "상품권",
        link: "/supports/giveaway",
      },
      {
        title: "제휴카드",
        link: "/supports/partnership",
      },
      {
        title: "카카오톡 친구",
        link: "/supports/kakaotalk-friends",
      },
      {
        title: "이벤트&프로모션",
        link: "/supports/events",
      },
    ],
  },
  {
    title: "HR",
    link: "/hr",
    subNav: [
      {
        title: "인재상",
        link: "/hr/talent",
      },
      {
        title: "교육",
        link: "/hr/education",
      },
      {
        title: "인사제도",
        link: "/hr/welfare",
      },
    ],
  },
  {
    title: "Recruit",
    link: "/recruit",
  },
];

export default MobileNavData;
