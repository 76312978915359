import React, { useState } from "react";
import { useParams, useRouteMatch, Redirect } from "react-router-dom";
import HibarinMenuNavigator from "./HibarinMenuNavigator";
import HibarinBanner from "../banner/BrandBanner";
import BannerImage from "../../../../static/images/mobile/img-hibarin-top@2x.png";
import BannerLogoIcon from "../../../../static/svg/mobile/hibarin-logo-header-w.svg";
import HibarinMenuTable from "./HibarinMenuTable";
import styles from "./mobile_hibarin.module.scss";
import Fab from "../../../brand/fab/Fab";
import { newBannerData } from "../../../brand/HibarinMenuData";

function HiabrinMenuContentCaseRenderer({ menu }) {
  switch (menu) {
    case "katsu":
      return (
        <>
           <div>
            <img src={newBannerData[menu]} alt="katsu" className="img-fill" />
          </div>
        </>
      );
    case "friends":
     	return (
        <>
           <div>
            <img src={newBannerData[menu]} alt="noodle and rice" className="img-fill" />
          </div>
        </>
      );
    case "drink":
      return (
        <>
          <div>
            <img src={newBannerData[menu]} alt="drink" className="img-fill" />
          </div>
  
        </>
      );
    default:
      return <Redirect to="/brand/hibarin/menu/katsu" />;
  }
}

export default function HibarinMenuContent() {
  const params = useParams();
  const [category] = useState(params.menu ? params.menu : "katsu");
  const { url } = useRouteMatch();

  return (
    <>
      <HibarinBanner
        subject={params.subject}
        url={url}
        image={BannerImage}
        titleLogo={BannerLogoIcon}
      />
      <HibarinMenuNavigator category={category} />
      <HiabrinMenuContentCaseRenderer menu={category} />
      <Fab subject="hibarin" />
    </>
  );
}
