import React from "react";
import styles from "./mobile_about.module.scss";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import image1 from "../../../static/images/financialStatements-1.png";

export default function AboutFinancialStatements() {
    return (
        <>
            <section className={styles.aboutFinancialStatements}>
                <div className={styles.aboutFinancialStatementsWrapper}>
                    <div className={styles.aboutFinancialStatementsTitle}>
                        <div className={styles.aboutFinancialStatementsTitleText}>재무제표</div>
                    </div>
                    <div className={styles.aboutFinancialStatementsSection}>
                        <Accordion className={styles.AccordionWrapper}>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    <div className={styles.cardHeaderWrapper}>
                                        <div className={styles.cardHeaderNum}>1</div>
                                        <div>
                                            <div className={styles.cardHeaderMain}>
                                                <div className={styles.cardHeaderTitle}>
                                                    캘리스코 2023 재무제표
                                                </div>
                                            </div>
                                            <div className={styles.cardHeaderSideWrapper}>
                                                <div className={styles.cardHeaderSideDate}>2024.04.08</div>
                                            </div>
                                        </div>
                                        <div className={styles.cardHeaderCategory}>캘리스코</div>
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className={styles.cardDescWrapper}>
                                        <div className={styles.cardDescText}>
                                            <img src={image1} alt="img" className="img-fill"/>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                </div>
            </section>
        </>
    );
}