import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import Nav from "../../components/nav/Nav";
import MainVerticalList from "../../components/mainVerticalList/MainVerticalList";
import MobileHomePage from "../mobile/HomePage";
import {useMobileCheck} from "../../utils/mobile";
import FinancialStatements from "../../static/images/financialStatements-1.png";
import {ModalContext} from "../../components/brand/modal/modal-context";
import FinancialStatementsModal from "../../components/brand/modal/FinancialStatementsModal";

function HomePageComponent() {
    return (
        <>
            <Nav/>
            <MainVerticalList/>
        </>
    );
}

export default function HomePage() {
    const {setModalOpen} = useContext(ModalContext);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    useLayoutEffect(() => {
        window.addEventListener("resize", (e) => {
            setWidth(e.target.innerWidth);
            setHeight(e.target.innerHeight);
        });

        return () => {
            window.removeEventListener("resize", (e) => {
                setWidth(e.target.innerWidth);
                setHeight(e.target.innerHeight);
            });
        };
    }, []);

    const kaliscoPopupValidate = () => {
        return sessionStorage.getItem("popupDisplayed") !== "true";
    }

    const kaliscoPopup = () => {
        const modalData = {
            delayKey: 1,
        }
        sessionStorage.setItem("popupDisplayed", "true");
        setModalOpen(modalData);
    }

    useEffect(() => {
        /*kaliscoPopup();*/
    }, []);

    return (
        <>
            {useMobileCheck(width) ? (
                <HomePageComponent/>
            ) : (
                <MobileHomePage rootHeight={height}/>
            )
            }
            <FinancialStatementsModal/>
        </>
    )


}
