import React from "react";
import ContactBanner from "./ContactBanner";
import styles from "./contact.module.scss";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import "./bootstrap.scss";
import FaqData from "./FaqData";
import {Link} from "react-router-dom";

const FaqDataLength = FaqData.length;

function CustomerFaq() {
    const handleMoveMailClick = () => {
        const authToken = window.localStorage.getItem("userAuthToken");
        if (!authToken) {
            window.location.assign("/login");
            return;
        }
        window.location.assign("/mail");
    }

    return (<>
        <ContactBanner subject="contact"/>
        <section className={styles.noticeSection}>
            <div className="container">
                <form>
                    <div className={styles.searchFormWrapper}>
                        <div className={styles.searchFormTitle}>FAQ</div>
                    </div>
                </form>
                <div>
                    <Accordion className={styles.noticeAccordionWrapper}>
                        {FaqData.reverse().map((d, i) => (<Card key={i}>
                            <Accordion.Toggle as={Card.Header} eventKey={i + 1}>
                                <div className={styles.headerTitleWrapper}>
                                    <div className={styles.headerTitleNumber}>
                                        {FaqDataLength - i}
                                    </div>
                                    <div className={styles.headerTitle}>{d.question}</div>
                                    <div className={styles.headerTitleSideWrapper}>
                                        <div className={styles.headerTitleCreatedAt}>
                                            2020.10.14
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={i + 1}>
                                <Card.Body>
                                    {d.description}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>))}
                    </Accordion>
                </div>
            </div>
        </section>
        <section className={styles.contactInquirySection}>
            <div className="container">
                <div className={styles.contactInquirySectionTitle}>유선 문의</div>
            </div>
            <div className="container">
                <div className={styles.contactInquiryDescription}>
                    원하시는 문의 내용이 FAQ에 없으신 경우<br/>
                    고객센터(1833-4550) 유선 번호 연결을 통해 편히 문의 하실 수 있습니다.
                </div>
                <div className={styles.contactMoveContainer}>
                    <a href="tel:1833-4550"
                       className={styles.contactMoveContainer}>
                        <button className={styles.contactButton}>
                            1833-4550
                        </button>
                    </a>
                </div>

            </div>
        </section>

        <section className={styles.contactInquirySection}>
            <div className="container">
                <div className={styles.contactInquirySectionTitle}>온라인 문의</div>
            </div>
            <div className="container">
                <div className={styles.contactInquiryDescription}>
                    온라인 문의는 로그인 시 등록 가능합니다.
                </div>
                <div className={styles.contactMoveContainer}>
                    <button onClick={handleMoveMailClick} className={styles.contactButton}>
                        문의하기
                    </button>
                </div>
            </div>
        </section>
    </>);
}

export default CustomerFaq;
