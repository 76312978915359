import React, {useContext, useLayoutEffect, useState} from "react";
import ReactModal from "react-modal";
import { ModalContext } from "./modal-context";
import styles from "./modal.module.scss";

ReactModal.setAppElement("#root");

function FinancialStatementsModal() {
    const [width, setWidth] = useState(window.innerWidth);
    useLayoutEffect(() => {
        window.addEventListener("resize", (e) => {
            setWidth(e.target.innerWidth);
        });

        return () => {
            window.removeEventListener("resize", (e) => {
                setWidth(e.target.innerWidth);
            });
        };
    }, []);
    const { data, isOpen, setModalClose } = useContext(ModalContext);
    return (
        <ReactModal
            isOpen={isOpen}
            overlayClassName={styles.financialStatementsOverlayWrapper}
            className={styles.financialStatementsContentWrapper}
            bodyOpenClassName="menu-modal-open"
            onRequestClose={() => setModalClose()}
        >
            <div className={styles.financialStatePopupHeader}>
                알려드립니다.
            </div>
            <div className={styles.financialStatePopupContent}>
                재무제표 메뉴에 <br/>
                [2023년 결산공고] 가 <br/>
                공시 되었습니다.
            </div>
            {width > 1200 ? (
                <div className={styles.financialStatePopupFooterForPc}>
                    <div className={styles.financialStatePopupCloseBtn} onClick={() => setModalClose()}>닫기</div>
                </div>
            ) : (
                <div className={styles.financialStatePopupFooterForMobile} onClick={() => setModalClose()}>
                    닫기
                </div>
            )}
        </ReactModal>
    );
}

export default FinancialStatementsModal;
