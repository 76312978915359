import React, { useState } from "react";
import Footer from "../Footer";
import styles from "./hibarin.module.scss";
import HibarinBanner from "./HibarinBanner";
import { useParams } from "react-router-dom";
import CategoryLink from "../categoryButton/CategoryLink";
import ModalProvider from "./modal/modal-provider";
import Fab from "./fab/Fab";
import { newData, newBannerData } from "./HibarinMenuData";

function HibarinMenuContent() {
  const params = useParams();
  const categoryData = [
    {
      text: "Katsu",
      type: "katsu",
      link: "/brand/hibarin/menu/katsu",
    },
    {
      text: "Noodle & Rice",
      type: "friends",
      link: "/brand/hibarin/menu/friends",
    },
    {
      text: "Drinks",
      type: "drink",
      link: "/brand/hibarin/menu/drink",
    },
    /*{
            text: "Side",
            type: "side",
            link: "/brand/hibarin/menu/side",
        },*/
  ];
  const [category, setCategory] = useState(params.menu ? params.menu : "katsu");

  return (
    <>
      <HibarinBanner subject={params.subject} />
      <section className={styles.menuDetailSection}>
        <div className={styles.categoryButtonListWrapper}>
          {categoryData.map((d, i) => (
            <CategoryLink
              key={i}
              {...d}
              parentType={category}
              clickEvent={() => setCategory(d.type)}
            />
          ))}
        </div>
        {/* <div className="container">
                    <div className={styles.menuDetailPanelWrapper}>
                        {HibarnMenuData[category] &&
                            HibarnMenuData[category].map((d, i) => (
                                <MenuPanel key={i} {...d} />
                            ))}
                    </div>
                </div> */}
        <div className={`row ${styles.fixedMenuListFlexWrapper}`}>
          <div className="col">
            <img
              src={newBannerData[category] && newBannerData[category]}
              alt="hibarin"
              className="img-fill"
            />
          </div>
      
        </div>
      </section>
      <Footer />
      <Fab subject="hibarin" />
    </>
  );
}

export default HibarinMenuContent;
