import React from "react";
import styles from "../main_footer_circle.module.scss";

function SupportLogo({ hovered }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48.572"
            height="53.011"
            viewBox="0 0 48.572 53.011"
        >
            <g transform="translate(-18.343 -16)">
                <path
                    className={hovered ? styles.hoveredLogo : styles.normalLogo}
                    d="M66.8,304.461a.888.888,0,0,0-.778-.461,11.262,11.262,0,0,0-9.146,4.707l-1.175,1.645-7.58,2.527H47.75l6.475-6.475a.888.888,0,0,0-.628-1.516H51.624A15.313,15.313,0,0,0,40.8,309.327H36a9.786,9.786,0,0,0-6.965,2.885L18.343,322.9,19.6,324.16l10.693-10.693A8.022,8.022,0,0,1,36,311.1h5.166a.888.888,0,0,0,.628-.26l.184-.184a13.548,13.548,0,0,1,9.473-3.994l-8.25,8.25,1.256,1.256,1.516-1.516h2.3a.889.889,0,0,0,.281-.045l7.991-2.664a.889.889,0,0,0,.442-.326l1.343-1.88a9.485,9.485,0,0,1,5.983-3.805l-5.1,8.012L47.2,319.093H34.952a.888.888,0,0,0-.628.26l-4.439,4.439,1.256,1.256,4.179-4.179H47.382a.888.888,0,0,0,.35-.072l12.43-5.327a.888.888,0,0,0,.4-.339l6.215-9.766A.888.888,0,0,0,66.8,304.461Z"
                    transform="translate(0 -256.038)"
                />
                <path
                    className={hovered ? styles.hoveredLogo : styles.normalLogo}
                    d="M112.888,27.542h.888V50.626a.888.888,0,0,0,.888.888h9.766V49.738h-8.878v-22.2h9.766V48.85h1.776V27.542h1.776v20.42h1.776V27.542h9.766V48.85h1.776V27.542h.888a.888.888,0,0,0,.888-.888V23.1a.888.888,0,0,0-.888-.888h-4.227A3.993,3.993,0,0,0,135.528,16a6.644,6.644,0,0,0-4.986,2.252.887.887,0,0,0-.785-.476h-3.551a.887.887,0,0,0-.786.475A6.643,6.643,0,0,0,120.435,16a3.993,3.993,0,0,0-3.32,6.215h-4.227A.888.888,0,0,0,112,23.1v3.551a.888.888,0,0,0,.888.888Zm16.869-3.551v1.776h-3.551V23.991Zm12.43,1.776H131.533V23.991h10.654Zm-6.659-7.991a2.22,2.22,0,0,1,0,4.439h-4.863A4.89,4.89,0,0,1,135.528,17.776Zm-6.659,1.776v2.664h-1.776V19.551ZM118.215,20a2.222,2.222,0,0,1,2.22-2.22,4.89,4.89,0,0,1,4.863,4.439h-4.863A2.222,2.222,0,0,1,118.215,20Zm-4.439,4H124.43v1.776H113.776Z"
                    transform="translate(-83.263 0)"
                />
            </g>
        </svg>
    );
}

export default SupportLogo;
