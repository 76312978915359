import React from "react";
import styles from "./mobile_support.module.scss";
//import ImgWarningThinking from "../../../static/images/mobile/img-thinking@2x.png";
import membershipImage from "../../../static/images/membership-small.png";

export default function SupportUnderConstruction() {
  return (
   <div className={styles.giveawaySection}>
	  <div className={styles.giveawayPanelMembership}>
		<div>
        	<img src={membershipImage} alt="membership" style={{display: "block",margin: "0 auto"}} /> 
      	</div>
      </div>
     </div>
  );
}
