import katsu1 from "../../static/images/menu-hibarin-kastu-1.png";
import katsu2 from "../../static/images/menu-hibarin-kastu-2.png";
import katsu3 from "../../static/images/menu-hibarin-kastu-3.png";
import katsu4 from "../../static/images/menu-hibarin-kastu-4.png";
import katsu5 from "../../static/images/menu-hibarin-kastu-5.png";
import katsu6 from "../../static/images/menu-hibarin-kastu-6.png";
import katsu7 from "../../static/images/menu-hibarin-kastu-7.png";
import katsu8 from "../../static/images/menu-hibarin-kastu-8.png";
import katsu9 from "../../static/images/menu-hibarin-kastu-9.png";
import katsu10 from "../../static/images/menu-hibarin-kastu-10.png";
import katsu11 from "../../static/images/menu-hibarin-kastu-11.png";
import katsu12 from "../../static/images/menu-hibarin-kastu-12.png";
import katsu13 from "../../static/images/menu-hibarin-kastu-13.png";
import katsu14 from "../../static/images/menu-hibarin-kastu-14.png";
import katsu15 from "../../static/images/menu-hibarin-kastu-15.png";
import donburi1 from "../../static/images/menu-hibarin-donburi-1.jpg";
import donburi2 from "../../static/images/menu-hibarin-donburi-2.jpg";
import donburi3 from "../../static/images/menu-hibarin-donburi-3.jpg";
import donburi4 from "../../static/images/menu-hibarin-donburi-4.jpg";
import donburi5 from "../../static/images/menu-hibarin-donburi-5.jpg";
import donburi6 from "../../static/images/menu-hibarin-donburi-6.jpg";
import donburi7 from "../../static/images/menu-hibarin-donburi-7.jpg";
import donburi8 from "../../static/images/menu-hibarin-donburi-8.jpg";
import donburi9 from "../../static/images/menu-hibarin-donburi-9.jpg";
import noodles1 from "../../static/images/menu-hibarin-noodles-1.jpg";
import noodles2 from "../../static/images/menu-hibarin-noodles-2.jpg";
import noodles3 from "../../static/images/menu-hibarin-noodles-3.jpg";
import noodles4 from "../../static/images/menu-hibarin-noodles-4.jpg";
import noodles5 from "../../static/images/menu-hibarin-noodles-5.jpg";
import noodles6 from "../../static/images/menu-hibarin-noodles-6.jpg";
import noodles7 from "../../static/images/menu-hibarin-noodles-7.jpg";
import noodles8 from "../../static/images/menu-hibarin-noodles-8.jpg";
import noodles9 from "../../static/images/menu-hibarin-noodles-9.jpg";
import noodles10 from "../../static/images/menu-hibarin-noodles-10.jpg";
import side1 from "../../static/images/menu-hibarin-side-1.jpg";
import side2 from "../../static/images/menu-hibarin-side-2.jpg";
import side3 from "../../static/images/menu-hibarin-side-3.jpg";
import side4 from "../../static/images/menu-hibarin-side-4.jpg";
import side5 from "../../static/images/menu-hibarin-side-5.jpg";
import side6 from "../../static/images/menu-hibarin-side-6.jpg";
import side7 from "../../static/images/menu-hibarin-side-7.jpg";
import side8 from "../../static/images/menu-hibarin-side-8.jpg";
import side9 from "../../static/images/menu-hibarin-side-9.jpg";
import side10 from "../../static/images/menu-hibarin-side-10.jpg";
import side11 from "../../static/images/menu-hibarin-side-11.jpg";
import side12 from "../../static/images/menu-hibarin-side-12.jpg";
import side13 from "../../static/images/menu-hibarin-side-13.jpg";
import newImage1 from "../../static/images/img-hibarin-menu-katsu1.png";
import newImage2 from "../../static/images/img-hibarin-menu-katsu2.png";
import newImage3 from "../../static/images/img-hibarin-menu-katsu3.png";
import newImage4 from "../../static/images/img-hibarin-menu-katsu4.png";
import newImage5 from "../../static/images/img-hibarin-menu-katsu5.png";
import newImage6 from "../../static/images/img-hibarin-menu-katsu6.png";
import newImage7 from "../../static/images/img-hibarin-menu-katsu7.png";
import newImage8 from "../../static/images/img-hibarin-menu-katsu8.png";
import newImage9 from "../../static/images/img-hibarin-menu-katsu9.png";
import newImage10 from "../../static/images/img-hibarin-menu-katsu10.png";
import newImage11 from "../../static/images/img-hibarin-menu-katsu11.png";
import newImage12 from "../../static/images/img-hibarin-menu-katsu12.png";
import bannerImage1 from "../../static/images/img-hibarin-menu-detail-1.jpg";
import bannerImage2 from "../../static/images/img-hibarin-menu-detail-2.jpg";
import bannerImage3 from "../../static/images/img-hibarin-menu-detail-3.jpg";

export const newData = {
  katsu: [
    {
      no: "01",
      image: newImage1,
      enTitle: "CHINESE CHICKEN KATSU",
      title: "유린기카츠",
      description:
        "쫄깃한 닭다리 순살을 튀겨 낸 치킨카츠에\n상큼한 유린기 소스를 부어먹는 카츠",
      price: "14.0",
    },
    {
      no: "02",
      image: newImage2,
      enTitle: "PREMIUM SHRIMP KATSU",
      title: "프리미엄 에비카츠",
      description:
        "여러가지 특제 소스에 찍어먹는\n히바린이 재해석한 프리미엄 에비 카츠",
      price: "19.0",
    },
    {
      no: "03",
      image: newImage3,
      enTitle: "MOAZZA-CHEDDAR CHEESE KATSU",
      title: "모짜체다치즈카츠",
      description:
        "고소하고 부드러운 모짜체다 치즈와\n새우 스테이크를 함께 즐기는 베스트 카츠",
      price: "14.5",
    },
    {
      no: "04",
      image: newImage4,
      enTitle: "MENTAIKO CHEESE KATSU",
      title: "명란치즈카츠",
      description:
        "얇게 저민 등심을 층층이 쌓아 치즈와 함께\n튀긴 카츠 위에 명란소스를 듬뿍 올려 낸 명란카츠",
      price: "16.0",
    },
    {
      no: "05",
      image: newImage5,
      enTitle: "HIBARIN KATSU (130G / 200G)",
      title: "히바린 카츠 정식",
      description:
        "3.5cm 두께의 두툼한 돈육을 두들겨 돼지고기의\n진한 풍미와 육즙이 가득한 히바린 시그니처 카츠",
      price: "13.0 / 17.0",
    },
  ],
  friends: [
    {
      no: "06",
      image: newImage6,
      enTitle: "MODERN KATSU-DON",
      title: "모던 카츠동",
      description:
        "히바린만의 특제 소스와 카츠, 계란, 김치가 어우러져 부드러운 덮밥",
      price: "13.5",
    },
    {
      no: "07",
      image: newImage7,
      enTitle: "AVOCADO SALMON RICE",
      title: "아보카도 연어라이스",
      description:
        "슈퍼푸드 아보카도와 생연어 위에 간장소스를\n취향에 맞게 부어 비벼먹는 시그니쳐 라이스",
      price: "15.0",
    },
    {
      no: "08",
      image: newImage8,
      enTitle: "SALMON AVO KATSU ROLL",
      title: "연어아보카츠롤",
      description: "세계 3대푸드 연어와 아보카도가 듬뿍 들어간 빅 카츠 롤",
      price: "12.0",
    },
    {
      no: "09",
      image: newImage9,
      enTitle: "SEAFOOD KUNG PAO NOODLES",
      title: "해물쿵파오누들",
      description: "카츠와 함께 즐기는 다양한 해물이 들어간 매콤 볶음 누들",
      price: "14.0",
    },
    {
      no: "10",
      image: newImage10,

      enTitle: "SPICY BULGOGI NOODLE SOUP",
      title: "육개장불고기누들",
      description: "얼큰한 육개장 누들에 불고기가 듬뿍! 들어간 카츠의 짝꿍",
      price: "13.0",
    },
    {
      no: "11",
      image: newImage11,
      enTitle: "BUCKWHEAT SOBA NOODLE",
      title: "히야시소바",
      description:
        "상큼한 레몬 슬라이스와 시원한 간 무가\n가득 올라간 메밀 소바",
      price: "12.0",
    },
    {
      no: "12",
      image: newImage12,

      enTitle: "KIDS' KATSU",
      title: "키즈메뉴 (카레)",
      description:
        "베스트 카츠로 담아 낸 프리미엄 키즈 정식\n(커리 라이스 & 누들 중 선택)",
      price: "9.5",
    },
  ],
};

export const newBannerData = {
  katsu: bannerImage1,
  friends: bannerImage2,
  drink: bannerImage3,
};

const HibarinMenuData = {
  katsu: [
    {
      title: "히바린 카츠(히레/로스)",
      enTitle: "hibarin katsu(tenderloin/sirloin)",
      image: katsu1,
      description:
        "#히노끼에이징 #피톤치드 #숙성의 미학 #2.2cm두께 #200g #시그니처메뉴",
      price: "17.0 / 16.0",
    },
    {
      image: katsu2,
      title: "제주흑돈카츠(히레/로스)",
      enTitle: "jeju black pork katsu\n(tenderloin/sirloin)",
      description:
        "#제주청정지역 #제주흑다돈 #일품 #부드럽고 #고소한맛 #150g #육즙풍부",
      price: "16.0 / 15.0",
    },
    {
      image: katsu3,
      title: "카사네 카츠",
      enTitle: "24 layer pork katsu",
      description: "#24겹 #부드러운 식감 #층층삼겹",
      price: "14.5",
    },
    {
      image: katsu4,
      title: "코돈부르 카츠",
      enTitle: "cordon bleu katsu",
      description: "#24겹 #치즈야채듬뿍 #아삭 #고소",
      price: "16.0",
    },
    {
      image: katsu5,
      title: "모짜렐라 치즈 카츠",
      enTitle: "mozzarella cheese katsu",
      description: "#치즈매니아 #부드럽고진한 #자연치즈",
      price: "14.0",
    },
    {
      image: katsu6,
      title: "명란치즈카츠",
      enTitle: "cod roe & cheese katsu",
      description:
        "#모짜렐라 #명란 #꽈리고추튀김 #한입스타일 #술안주굿 #포토제닉",
      price: "16.0",
    },
    {
      image: katsu7,
      title: "콰트로 치즈 카츠",
      enTitle: "Quatro cheese katsu",
      description:
        "#4가지치즈 #치즈홀릭 #모짜렐라 #체다 #고르곤졸라 #그라나파다노",
      price: "16.0",
    },
    {
      image: katsu8,
      title: "씨푸드 카츠",
      enTitle: "assorted seafood katsu set",
      description: "#통새우 새우살스테이크 흰살생선카츠 #매콤크림",
      price: "16.0",
    },
    {
      image: katsu9,
      title: "비프함박카츠",
      enTitle: "beef hamburg katsu",
      description: "#소고기를가득품은 #겉바속촉 #고소한수란",
      price: "16.0",
    },
    {
      image: katsu10,
      title: "키즈카츠",
      enTitle: "kid katsu with udon and katsu(shrimp/cheese)",
      description: "#미니카츠 #우동 #주먹밥 #새우 #치즈",
      price: "9.0",
    },
    {
      image: katsu11,
      title: "bone-in 갈비 카츠",
      enTitle: "bone-in steak katsu",
      description:
        "#t-bone이라 #안심등심 같이 먹는 #초대형 #300g #육즙풍부 #스페셜카츠 #진정한 #고기맛 #겉바속촉 #히노끼에이징 #수퍼사이즈 #signature #최상의 맛",
      price: "30.0",
    },
    {
      image: katsu12,
      title: "블랙 카츠(히레/로스)",
      enTitle: "black katsu(tenderloin/sirloin)",
      description:
        "#오징어먹물 #블랙카카오 #블랙빵가루 #고소한 #평범한것먹기싫은당신",
      price: "16.0 / 15.0",
    },
    {
      image: katsu13,
      title: "마늘치즈 24겹 카츠",
      enTitle: "24 layer pork katsu \nwith cheese and garlic",
      description: "#24겹 #사이사이마늘 #치즈 #고소",
      price: "15.5",
    },
    {
      image: katsu14,
      title: "모짜체다 치즈 카츠",
      enTitle: "mozza cheddar cheese katsu",
      description: "#모짜렐라 #체다치즈 #2가지치즈 #치즈매니아 #부드럽고 진한",
      price: "15.0",
    },
    {
      image: katsu15,
      title: "테판24겹카츠",
      enTitle: "original 24layer pork katsu",
      descirption: "#24겹 #바삭촉촉 #지글지글 #태판 #뜨거운 #양배추",
      price: "15.0",
    },
  ],
  donburi: [
    {
      image: donburi1,
      title: "아보카도 연어 라이스",
      enTitle: "avocado+salmon rice bowl",
      description: "#아보카도 #슈퍼푸드 #덮밥 #연어회 #와사비 #건강",
      price: "15.0",
    },
    {
      image: donburi2,
      title: "숯불 불고기 핫팟",
      enTitle: "charcoal-grilled bulgogi hot pot",
      description: "#숯불불고기 #돌솥 #덮밥",
      price: "15.0",
    },
    {
      image: donburi3,
      title: "카츠동",
      enTitle: "katsu rice bowl",
      description: "#계란 #카츠 #특제소스 #덮밥 #카츠동",
      price: "13.0",
    },
    {
      image: donburi4,
      title: "카레라이스 + 카츠 or 새우",
      enTitle: "curry rice + katsu or shrimp",
      description: "#바삭한 카츠 #통새우를 얹은 #카레라이스",
      price: "12.0",
    },
    {
      image: donburi5,
      title: "24겹 김치 카츠 마끼",
      enTitle: "24-layer kimchi katsu maki",
      description: "#24겹카사네 #바삭촉촉 #사이사이김치",
      price: "11.0",
    },
    {
      image: donburi6,
      title: "김치 카츠동",
      enTitle: "kimchi katsu rice bowl",
      description: "#부드러운 돈카츠 #계란 #아삭한김치 #완벽조화",
      price: "13.5",
    },
    {
      image: donburi7,
      title: "에비동",
      enTitle: "shrimp rice bowl",
      description: "#통새우카츠 #덮밥 #에비동 #씨푸드매니아",
      price: "13.0",
    },
    {
      image: donburi8,
      title: "명란알밥",
      enTitle: "rice with mentaike and fish roe",
      description: "#명란듬뿍 #산뜻한한끼 #히바린특제알밥",
      price: "13.5",
    },
    {
      image: donburi9,
      title: "와사비 슈림프 마끼",
      enTitle: "wasabi shrimp maki",
      description: "#통새우 #마끼 #크레이지롤 #와사비 #오이",
      price: "12.0",
    },
  ],
  noodles: [
    {
      image: noodles1,
      title: "육개장 불고기 누들",
      enTitle: "spicy beef noodle with bulgogi",
      description: "#얼큰불고기 누들 #칼칼 #개운 #국물이 #끝내줘요",
      price: "13.0",
    },
    {
      image: noodles2,
      title: "숯불 불고기 우동",
      enTitle: "charcoal-grilled bulgogi udon",
      description: "#불고기듬뿍 #숯불향 #가쓰오부시 국물 #든든함",
      price: "14.0",
    },
    {
      image: noodles3,
      title: "야채 가라아게 우동",
      enTitle: "Vegetable tempura udon",
      description: "#야채튀김듬뿍 #바삭바삭 #국물 #담백",
      price: "11.0",
    },
    {
      image: noodles4,
      title: "간사이 소바",
      enTitle: "mori soba noodle",
      description: "#찍먹 #쯔유 #소바 #시원쫄깃",
      price: "11.0",
    },
    {
      image: noodles5,
      title: "명란 크림 우동",
      enTitle: "mentaiko cream udon",
      description: "#크림소스 #명란 #모던일식파스타 #묘한중독",
      price: "13.0",
    },
    {
      image: noodles6,
      title: "히야시 소바",
      enTitle: "cool soba noodle",
      description: "#냉소바 #메밀면 #시원쫄깃 #여름",
      price: "12.0",
    },
    {
      image: noodles7,
      title: "카레 우동 + 카츠 or 새우",
      enTitle: "curry udon+katsu or shrimp",
      description: "#바삭한 카츠 #통새우를 얹은 #카레우동",
      price: "12.0",
    },
    {
      image: noodles8,
      title: "샐러드 소바",
      enTitle: "salad soba",
      description: "#어린잎채소 #소바면 #레몬참깨드레싱",
      price: "8.0",
    },
    {
      image: noodles9,
      title: "치킨카츠냉소바",
      enTitle: "cold chicken soba with lemon",
      description: "#시원상큼한소바 #바삭한치킨카츠",
      price: "15.0",
    },
    {
      image: noodles10,
      title: "청양불고기비빔소바",
      enTitle: "spicy bulgogi soba",
      description: "#매콤고소 특제청양소스 #비빔소바 #숯불향기",
      price: "15.0",
    },
  ],
  side: [
    {
      image: side1,
      title: "치즈 아란치니(2조각)",
      enTitle: "cheese arancini(2pcs)",
      description: "",
      price: "5.0",
    },
    {
      image: side2,
      title: "미니 카츠(2조각)",
      enTitle: "mini katsu(2pcs)",
      description: "",
      price: "3.5",
    },
    {
      image: side3,
      title: "1/2 로스 카츠",
      enTitle: "half roast katsu",
      description: "",
      price: "5.5",
    },
    {
      image: side4,
      title: "모짜렐라치즈 카츠(2조각)",
      enTitle: "mozzarella cheese katsu(2pcs)",
      description: "",
      price: "3.5",
    },
    {
      image: side5,
      title: "생선 카츠(2조각)",
      enTitle: "sea food katsu(2pcs)",
      description: "",
      price: "3.5",
    },
    {
      image: side6,
      title: "통새우 카츠(1조각/2조각)",
      enTitle: "shrimp katsu(1pc / 2pcs)",
      description: "",
      price: "3.5 / 5.0",
    },
    {
      image: side7,
      title: "안심미트볼카츠(3조각)",
      enTitle: "tenderioin meet ball katsu(3pcs)",
      description: "",
      price: "3.5",
    },
    {
      image: side8,
      title: "고로케(카레/크림치즈)",
      enTitle: "croquette(curry/creem cheese)",
      description: "",
      price: "3.5 / 4.5",
    },
    {
      image: side9,
      title: "미니소바",
      enTitle: "mini soba",
      description: "",
      price: "3.8",
    },
    {
      image: side10,
      title: "미니우동",
      enTitle: "mini udon",
      description: "",
      price: "3.8",
    },
    {
      image: side11,
      title: "소바 추가(면/장)",
      enTitle: "added soba(noodle/soup)",
      description: "",
      price: "5.0",
    },
    {
      image: side12,
      title: "카레소스",
      enTitle: "curry sauce",
      description: "",
      price: "2.0",
    },
    {
      image: side13,
      title: "밥",
      enTitle: "rice",
      description: "",
      price: "2.5",
    },
  ],
};

export default HibarinMenuData;
