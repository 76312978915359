import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import styles from "./mobile_contact.module.scss";
import "./bootstrap.scss";

import FaqData from "./FaqData";

export default function CustomerFaq() {
    const handleMoveMailClick = () => {
        const authToken = window.localStorage.getItem("userAuthToken");
        if (!authToken) {
            window.location.assign("/login");
            return;
        }
        window.location.assign("/mail");
    }

    return (
        <>
            <section className={styles.contactFaqSection}>
                <div className={styles.contactFaqWrapper}>
                    <div className={styles.contactFaqTitle}>FAQ</div>
                    <div>
                        <Accordion className={styles.AccordionWrapper}>
                            {FaqData.reverse().map((d, i) => (
                                <Card key={i}>
                                    <Accordion.Toggle as={Card.Header} eventKey={i + 1}>
                                        <div className={styles.cardHeaderWrapper}>
                                            <div className={styles.cardHeaderNum}>Q</div>
                                            <div>
                                                <div className={styles.cardHeaderMain}>
                                                    <div className={styles.cardHeaderTitle}>{d.question}</div>
                                                </div>
                                                <div className={styles.cardHeaderSideWrapper}>
                                                    <div className={styles.cardHeaderSideDate}>2020.10.14</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={i + 1}>
                                        <Card.Body className={styles.cardDescWrapper}>
                                            <div className={styles.cardDescText}>
                                                A. {d.description}
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            ))}
                        </Accordion>
                    </div>
                </div>
            </section>
            <section className={styles.contactInquirySection}>
                <div className={styles.contactInquirySectionTitle}>유선 문의</div>
                <div className={styles.contactInquiryDescription}>
                    원하시는 문의 내용이 FAQ에 없으신 경우<br/>
                    고객센터(1833-4550) 유선 번호 연결을 통해 편히 문의 하실 수 있습니다.
                </div>
                <a href="tel:1833-4550" className={styles.contactMoveContainer}>
                    <button className={styles.contactButton}>
                        1833-4550
                    </button>
                </a>
            </section>

            <section className={styles.contactInquirySection}>
                <div className={styles.contactInquirySectionTitle}>온라인 문의</div>
                <div className={styles.contactInquiryDescription}>
                    온라인 문의는 로그인 시 등록 가능합니다.
                </div>
                <div className={styles.contactMoveContainer}>
                    <button onClick={handleMoveMailClick} className={styles.contactButton}>
                        문의하기
                    </button>
                </div>
            </section>
        </>
    );
}