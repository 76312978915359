import React, {useEffect} from "react";
import styles from "./about_content.module.scss";
import Accordion from "react-bootstrap/Accordion";
import Footer from "../Footer";
import Card from "react-bootstrap/Card";
import image1 from "../../static/images/financialStatements-1.png";

function AboutFinancialStatements() {

    return (
        <>
            <section className={styles.financialStatementsWrapper}>
                <div className={styles.financialStatementsTitle}>
                    재무제표
                </div>
                <div className={styles.financialStatementsAccordionContainer}>
                    <Accordion className={styles.financialStatementsAccordionWrapper}>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                <div className={styles.headerTitleWrapper}>
                                    <div className={styles.headerTitleNumber}>1</div>
                                    <div className={styles.headerTitleCategory}>캘리스코</div>
                                    <div className={styles.headerTitle}>
                                        캘리스코 2023 재무제표
                                    </div>
                                    <div className={styles.headerTitleSideWrapper}>
                                        <div className={styles.headerTitleCreatedAt}>
                                            2024.04.08
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body className={styles.cardBody}>
                                    <img src={image1} alt="결산공고" className="img-fluid"/>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>

            </section>
            <Footer/>
        </>
    );
}

export default AboutFinancialStatements;
